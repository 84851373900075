.header {
  background: var(--header-bg);
  height: var(--header-height);
  position: fixed;
  width: 100vw;
  display: flex;
  justify-content: center;
  border-bottom: 2px ridge #191919;
  grid-area: header;
}

.container {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}

.logo {
  font-size: calc(1.5em + 1vh + 0.75vw);

  font-weight: 700;
  letter-spacing: 2px;
  text-shadow: 1px 1px 2px black;
  filter: drop-shadow(0px 2px 2px #000);
}

.logo__name {
  font-family: 'Amatic SC';
  color: var(--header-color);
}

.login {
  white-space: nowrap;
}

.login__button {
  padding: calc(0.25em + 0.25vw + 0.25vh);
  font-size: calc(0.75em + 0.25vw);
  font-weight: bold;
  color: var(--header-bg);
  background: var(--header-color);
  border: none;
  outline: none;
  border-radius: 4px;
}

.optionsButton {
  height: var(--header-height);
  display: inline-flex;
  justify-content: flex-end;
  height: auto;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0px 0px 0px 1px #e6e6e6;
  white-space: nowrap;
  color: var(--link-color);
  &:hover {
    background: #e6e6e6;
    box-shadow: 0px 0px 0px 1px blue;
    color: var(--link-hover);
  }
}

.profile__image {
  border-radius: 6px;
  border: none;
  outline: none;
  height: calc(2em + 0.5vw);
  width: calc(2em + 0.5vw);
}

.username {
  color: whitesmoke;
  margin-right: 16px;
}

.wrapper {
  display: flex;
  align-items: center;
}

.visuallyHidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
