.sidebar__list__item {
  margin: 32px 0;
  display: flex;
  align-items: center;
  font-size: 0.85em;
  color: #c1c6cb;
}

.image {
  margin-left: 16px;
  width: 15%;
  height: auto;
}

.name {
  margin-left: 8px;
}
