.sidebar {
  height: var(--sidebar-height);
  width: var(--sidebar-width);
  top: var(--header-height);

  background: var(--header-bg);
  position: fixed;
  z-index: 2;

  border-right: 1px solid #202020;
  grid-area: sidebar;

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: hsl(215, 23%, 45%);
    border: none; //1px solid hsl(35, 100%, 50%);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: hsl(215, 23%, 45%);
  }
}

.container {
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.list {
  position: absolute;
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}

@media (hover: none) {
  .sidebar {
    display: none;
  }
}
