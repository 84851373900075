.app {
  width: 100%;
  height: 100%;
}

.container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: var(--sidebar-width) auto;
  grid-template-rows: var(--banner-height) auto var(--footer-height);
  grid-template-areas:
    'header header'
    'sidebar content'
    'sidebar footer';
}

@media (max-width: 768px) {
  .container {
    // grid-template-rows: var(--banner-height) auto var(--footer-height);
    grid-template-columns: 100%;
    grid-template-areas:
      'header'
      'content'
      'footer';
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .container {
    // grid-template-rows: var(--banner-height) auto var(--footer-height);
    grid-template-columns: 100%;
    grid-template-areas:
      'header'
      'content'
      'footer';
  }
}
