* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-family: 'Lato', sans-serif;
}

:root {
  --header-bg: hsl(215, 25%, 23%);
  --header-color: #e6e6e6;
  --header-height: 60px;
  --input-height: 80px;
  --input-padding-bottom: 0.5rem;
  --footer-height: calc(var(--input-height) + var(--input-padding-bottom));
  --main-height: calc(100% - (var(--banner-height) + var(--footer-height)));
  --sidebar-height: calc(100vh - var(--header-height));
  --sidebar-width: 200px;
  --media-button-width: 240px;
  --max-button-width: 320px;

  --banner-height: 60px;
  --banner-font-size: calc(var(--banner-height) * 0.66666666666666666);
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: hsla(215, 0%, 83%, 0.5);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: hsl(215, 23%, 45%);
  border: 2px ridge hsl(215, 20%, 34%);

  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: hsl(215, 33%, 52%);
}
